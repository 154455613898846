import React from 'react';
// import { Link } from 'gatsby';
import '../components/style.css';

import SEO from '../components/seo';
import First from '../components/first';
import Aboutme from '../components/aboutme';
import Projects from '../components/projects';
import Others from '../components/others';
import Now from '../components/now';
import Contact from '../components/contact';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
const IndexPage = () => (
	<React.Fragment>
		<Helmet>
			<title>Himanshu Rattan | Software Developer | India</title>
			<meta
				name="description"
				content="Himanshu Rattan is a software developer pursuing Computer Science Engineering at SRMIST, Kattankulathur, India"
			/>
			<meta
				name="keywords"
				content="software development, web development, Himanshu Rattan, portfolio, computer science, India, SRMIST, Tech"
			/>
			<meta property="og:title" content="Himanshu Rattan | Software Developer | India" />
			<meta property="og:type" content="website" />

			<meta
				property="og:description"
				content="Himanshu Rattan is a software/web developer pursuing Computer Science Engineering at SRMIST, Kattankulathur. His work is focused on creating clean, creative and responsive interfaces, generally for the Web"
			/>
			<meta property="og:image" content="" />
			<meta property="og:url" content="https://himanshurattan.me/" />
			<meta name="robots" content="index, follow" />
			<meta name="author" content="Himanshu Rattan" />

			<meta property="twitter:card" content="" />
			<meta property="twitter:url" content="https://himanshurattan.me/" />
			<meta
				property="twitter:description"
				content="Himanshu Rattan is a software/web developer pursuing Computer Science Engineering at SRMIST, Kattankulathur. His work is focused on creating clean, creative and responsive interfaces, generally for the Web."
			/>
		</Helmet>
		<div className="main">
			<SEO title="Home" />
			<First />
			<Aboutme />
			<Now />
			<Projects />
			<Others />
			<Contact />
			<Footer />
		</div>
	</React.Fragment>
);

export default IndexPage;
