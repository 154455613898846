import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

function Projects() {
	return (
		<React.Fragment>
			<CssBaseline />
			<Container>
				<div className="projects">
					<h1>Projects</h1>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4}>
							<Paper className="project-card">
								<a
									href="https://twitter.com/NationalDay18"
									rel="noopener noreferrer"
									target="_blank"
									className="project-name"
								>
									Twitter Bot
								</a>
								<p>
									A bot that tweets what National Day it is since everyday is a national something
									day.
								</p>
								<div className="project-bottom">
									<a href="javascript:;" className="stack">
										<svg
											version="1.1"
											id="Capa_1"
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											viewBox="0 0 386.617 386.617"
										>
											<g>
												<g>
													<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
												</g>
											</g>
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
										</svg>
										Tweepy, Python, Heroku
									</a>
									<a
										href="https://twitter.com/NationalDay18"
										className="visit"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-globe" aria-hidden="true" />
									</a>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Paper className="project-card">
								<a
									href="https://github.com/HimanshuRattan/productive-twitter"
									rel="noopener noreferrer"
									target="_blank"
									className="project-name"
								>
									Productive Twitter
								</a>
								<p>A simple and minimal twitter extension to make twitter more productive.</p>
								<div className="project-bottom">
									<a href="javascript:;" className="stack">
										<svg
											version="1.1"
											id="Capa_1"
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											viewBox="0 0 386.617 386.617"
										>
											<g>
												<g>
													<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
												</g>
											</g>
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
										</svg>
										JS, CSS
									</a>
									<a
										href="https://github.com/HimanshuRattan/productive-twitter"
										className="visit"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-globe" aria-hidden="true" />
									</a>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Paper className="project-card">
								<a
									href="http://srmebazaar.com/"
									rel="noopener noreferrer"
									target="_blank"
									className="project-name"
								>
									Ebazaar
								</a>
								<p>
									An online portal designed exclusively for the students and faculty of SRM Institute
									of Science and Technology to buy andsell new and old products from a wide range of
									categories.
								</p>
								<div className="project-bottom">
									<a href="javascript:;" className="stack">
										<svg
											version="1.1"
											id="Capa_1"
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											viewBox="0 0 386.617 386.617"
										>
											<g>
												<g>
													<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
												</g>
											</g>
											<g>
												<g>
													<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
												</g>
											</g>
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
											<g />
										</svg>
										PHP, MySQL, JS, CSS
									</a>
									<a
										href="#"
										className="visit"
									>
										<i class="fa fa-globe" aria-hidden="true" />
									</a>
								</div>
							</Paper>
						</Grid>
					</Grid>
					{/* dont touch code above this */}
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={8}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={6}>
									<Paper className="project-card">
										<a
											href="http://scif.srmonline.net/"
											rel="noopener noreferrer"
											target="_blank"
											className="project-name"
										>
											SCIF
										</a>
										<p>
											SCIF portal provides consolidated state-of-the-art research facilities
											available in SRMIST and allows users to directly reserve time for use.
										</p>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												PHP, MySQL, JS, CSS
											</a>
											<a
												href="http://scif.srmonline.net/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<Paper className="project-card">
										<a
											href="https://evernote-clone-bd156.web.app/"
											rel="noopener noreferrer"
											target="_blank"
											className="project-name"
										>
											Evernote Clone
										</a>
										<p>A minimalistic clone of the evernote app.</p>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												ReactJS, Firebase
											</a>
											<a
												href="https://evernote-clone-bd156.web.app/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<Paper className="project-card">
										<a
											href="https://github.com/HimanshuRattan/react-meme-generator"
											rel="noopener noreferrer"
											target="_blank"
											className="project-name"
										>
											React Meme Generator
										</a>
										<p>Who doesn't like memes? Generate some of your own here.</p>
										<div className="bottom-row">
											<div className="project-bottom">
												<a href="javascript:;" className="stack">
													<svg
														version="1.1"
														id="Capa_1"
														xmlns="http://www.w3.org/2000/svg"
														x="0px"
														y="0px"
														viewBox="0 0 386.617 386.617"
													>
														<g>
															<g>
																<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
															</g>
														</g>
														<g>
															<g>
																<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
															</g>
														</g>
														<g>
															<g>
																<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
															</g>
														</g>
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
														<g />
													</svg>
													ReactJS, imgFlip API
												</a>
												<a
													href="https://github.com/HimanshuRattan/react-meme-generator"
													className="visit"
													rel="noopener noreferrer"
													target="_blank"
												>
													<i class="fa fa-globe" aria-hidden="true" />
												</a>
											</div>
										</div>
									</Paper>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<Paper className="project-card">
										<a
											href="https://msclubsrm.in/"
											rel="noopener noreferrer"
											target="_blank"
											className="project-name"
										>
											MS CLUB SRM
										</a>
										<p>
											A website for one of the most amazing community in SRM.<br />Includes dark
											mode and a portal for event attendees to download their certificates.
										</p>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												CSS, JS, Firebase, Bootstrap
											</a>
											<a
												href="https://msclubsrm.in/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Paper className="project-card">
								<span className="project-name">Websites</span>
								<p className="static-websites">
									<i class="fa fa-terminal" aria-hidden="true" />
									<a
										href="https://oorjaa.org/"
										className="website-name"
										rel="noopener noreferrer"
										target="_blank"
									>
										Oorjaa - Social Enterprise
									</a>
									<div className="project-bottom">
										<a href="javascript:;" className="stack">
											<svg
												version="1.1"
												id="Capa_1"
												xmlns="http://www.w3.org/2000/svg"
												x="0px"
												y="0px"
												viewBox="0 0 386.617 386.617"
											>
												<g>
													<g>
														<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
													</g>
												</g>
												<g>
													<g>
														<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
													</g>
												</g>
												<g>
													<g>
														<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
													</g>
												</g>
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
											</svg>
											Wordpress, CSS
										</a>
										<a
											href="https://oorjaa.org/"
											className="visit"
											rel="noopener noreferrer"
											target="_blank"
										>
											<i class="fa fa-globe" aria-hidden="true" />
										</a>
									</div>
								</p>
								<div className="extra-class">
									<p className="static-websites">
										<i class="fa fa-terminal" aria-hidden="true" />
										<a
											href="https://6xthsensemedia.com/"
											className="website-name"
											rel="noopener noreferrer"
											target="_blank"
										>
											6xth Sense Media Company
										</a>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												CSS, JS, Bootstrap, Netlify
											</a>
											<a
												href="https://6xthsensemedia.com/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</p>
								</div>
								<div className="project-bottom">
									<p className="static-websites">
										<i class="fa fa-terminal" aria-hidden="true" />
										<a
											href="https://aakashkapoor.gq/"
											className="website-name"
											rel="noopener noreferrer"
											target="_blank"
										>
											Photography Portfolio
										</a>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												CSS, JS, Bootstrap
											</a>
											<a
												href="https://aakashkapoor.gq/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</p>
								</div>
								<p className="static-websites">
									<i class="fa fa-terminal" aria-hidden="true" />
									<a
										href="https://rbhealthcare.netlify.app/"
										className="website-name"
										rel="noopener noreferrer"
										target="_blank"
									>
										RB Healthcare
									</a>
									<div className="project-bottom">
										<a href="javascript:;" className="stack">
											<svg
												version="1.1"
												id="Capa_1"
												xmlns="http://www.w3.org/2000/svg"
												x="0px"
												y="0px"
												viewBox="0 0 386.617 386.617"
											>
												<g>
													<g>
														<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
													</g>
												</g>
												<g>
													<g>
														<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
													</g>
												</g>
												<g>
													<g>
														<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
													</g>
												</g>
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
												<g />
											</svg>
											HTML, CSS, JS
										</a>
										<a
											href="https://rbhealthcare.netlify.app/"
											className="visit"
											rel="noopener noreferrer"
											target="_blank"
										>
											<i class="fa fa-globe" aria-hidden="true" />
										</a>
									</div>
								</p>
								<div className="project-bottom">
									<p className="static-websites">
										<i class="fa fa-terminal" aria-hidden="true" />
										<a
											href="https://cdats.netlify.app/"
											className="website-name"
											rel="noopener noreferrer"
											target="_blank"
										>
											CDATS SRM
										</a>
										<div className="project-bottom">
											<a href="javascript:;" className="stack">
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													x="0px"
													y="0px"
													viewBox="0 0 386.617 386.617"
												>
													<g>
														<g>
															<path d="M349.869,84.591L228.748,9.142c-19.564-12.187-51.306-12.192-70.879,0L36.748,84.591
			c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449
			C369.433,116.556,369.442,96.783,349.869,84.591z M211.584,168.669c-9.98,6.333-26.202,6.307-36.14,0l-97.578-61.923
			l97.578-61.734c9.98-6.314,26.202-6.287,36.14,0l97.578,61.734L211.584,168.669z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,172.542l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			l-97.578-61.923l0.022-0.014l-38.131-24.017l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,204.507,369.442,184.734,349.869,172.542z" />
														</g>
													</g>
													<g>
														<g>
															<path d="M349.869,257.875l-2.527-1.574l-127.273,80.305l-0.047-0.007l-8.438,5.354c-9.98,6.333-26.202,6.307-36.14,0
			L77.867,280.03l0.022-0.014L39.758,256l-3.01,1.875c-19.564,12.187-19.573,31.96,0,44.152l121.121,75.449
			c19.564,12.187,51.306,12.192,70.879,0l121.121-75.449C369.433,289.84,369.442,270.067,349.869,257.875z" />
														</g>
													</g>
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
													<g />
												</svg>
												CSS, Bootstrap, JS, Netlify
											</a>
											<a
												href="https://cdats.netlify.app/"
												className="visit"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i class="fa fa-globe" aria-hidden="true" />
											</a>
										</div>
									</p>
								</div>
							</Paper>
						</Grid>
					</Grid>
				</div>
			</Container>
		</React.Fragment>
	);
}
export default Projects;
