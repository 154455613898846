import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby-link';

function encode(data) {
	return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#43a699'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#43a699'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#43a699'
			},
			'&:hover fieldset': {
				borderColor: '#43a699'
			}
		}
	}
})(TextField);

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '100%'
		}
	},
	input: {
		color: '#7e9199'
	},
	button: {
		margin: theme.spacing(1)
	}
}));

export default function Contact() {
	const [ state, setState ] = React.useState({});
	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...state
			})
		})
			.then(() => navigate(form.getAttribute('action')))
			.catch((error) => alert(error));
	};
	const classes = useStyles();
	return (
		<Container className="contact">
			<h1>Contact</h1>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={6}>
					<Paper className="contact-card">
						<p className="form-p">Fill out the form and I will get back to you!</p>
						<form
							className={classes.root}
							autoComplete="off"
							name="contact"
							method="post"
							action="../thanks/"
							data-netlify="true"
							data-netlify-honeypot="bot-field"
							onSubmit={handleSubmit}
						>
							<input type="hidden" name="form-name" value="contact" />
							<p hidden>
								<label>
									Don’t fill this out: <input name="bot-field" onChange={handleChange} />
								</label>
							</p>
							<CssTextField
								id="standard-basic"
								label="Name"
								InputProps={{
									className: classes.input
								}}
								name="name"
								onChange={handleChange}
								required
							/>
							<br />
							<CssTextField
								id="standard-basic"
								label="Email"
								InputProps={{
									className: classes.input
								}}
								name="email"
								type="email"
								onChange={handleChange}
								required
							/>{' '}
							<br />
							<CssTextField
								id="standard-multiline-flexible"
								label="Message"
								InputProps={{
									className: classes.input
								}}
								name="message"
								onChange={handleChange}
								required
							/>{' '}
							<br />
							<Button
								variant="contained"
								style={{
									backgroundColor: '#43a699'
								}}
								className={classes.button}
								type="submit"
							>
								Send<i class="fa fa-paper-plane" aria-hidden="true" />
							</Button>
						</form>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<p className="email">
						Not a forms person?<br />Send an email to <br />
						<span>rattanhimanshu@gmail.com</span>
						<br />or connect on any of these platforms. 😀<br />
						<i>I try to be funny here.</i>
						<br />
						<div class="social-container">
							<ul class="social-icons">
								<li>
									<a
										href="https://github.com/HimanshuRattan"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-github" />
									</a>
								</li>

								<li>
									<a
										href="https://twitter.com/this_himanshu"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-twitter" />
									</a>
								</li>
								<li>
									<a
										href="https://www.linkedin.com/in/himanshu-rattan/"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-linkedin" />
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/hmmanshurattan/"
										rel="noopener noreferrer"
										target="_blank"
									>
										<i class="fa fa-instagram" />
									</a>
								</li>
							</ul>
						</div>
					</p>
				</Grid>
			</Grid>
		</Container>
	);
}
