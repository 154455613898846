import React from 'react';
import Container from '@material-ui/core/Container';

function Footer() {
	return (
		<Container>
			<p className="footer">
				Designed & Built by <span className="footer-special">Himanshu Rattan</span> using{' '}
				<span className="footer-special">React</span> and <span className="footer-special">Gatsby</span>
			</p>
		</Container>
	);
}

export default Footer;
