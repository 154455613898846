import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import brand from '../images/branding.png';
import app from '../images/hereforyou.jpg';
import api from '../images/api.png';
import publication from '../images/publication.png';

function Others() {
    return (
        <Container>
            <div className="projects">
                <h1>Other Stuff...</h1>
                {/* <Container className="first about-me"> */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className="success-box text-center">
                            <div className="first" style={{ height: '60px' }}>
                                <div class="bb">
                                    <img src={brand} alt="personal branding" />
                                </div>
                                {/* <div className="shadow scale"></div> */}
                            </div>
                            <div className="message">
                                <a
                                    href="https://youtu.be/E8hTOlWnEnI"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                >
                                    Personal Branding and Resume Building
                                    </a>
                                <p className="description">Hosted a session with Miri Rodriguez – Global Head of Internships, Microsoft on Personal Branding.</p></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className="success-box text-center">
                            <div className="first" style={{ height: '60px' }}>
                                <div class="bb">
                                    <img src={api} alt="API" />
                                </div>
                            </div>
                            <div className="message">
                                <a
                                    href="https://youtu.be/Ht_gcitcSWU"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                >
                                    GET-some-REST
                                    </a>
                                <p className="description">Organized and conducted a seminar on building REST APIs with NodeJS & Azure from scratch. 900+ views in YouTube.</p></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className="success-box text-center">
                            <div className="first" style={{ height: '60px' }}>
                                <div class="bb">
                                    <img src={app} alt="here for you logo" />
                                </div>
                            </div>
                            <div className="message">
                                <a
                                    href="https://linktr.ee/hereforyouapp/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                >
                                    Here for You App
                                    </a>
                                <p className="description">Managed a team of people that created a mental health app in close liason with India's top Mental Health Professionals.</p></div>
                        </div>
                    </Grid>

                </Grid>

                {/* second row */}
                <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12} md={4}>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12}>
                        <div className="success-box text-center">
                            <div className="first" style={{ height: '60px' }}>
                                <div class="bb">
                                    <img src={publication} alt="himanshu rattan publications" />
                                </div>
                            </div>
                            <div className="message">
                                {/* <a
                                    href="https://linktr.ee/hereforyouapp/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                > */}
                                    <span style={{fontSize:17}}>Publications</span>
                                    {/* </a> */}
                                <p className="description">Published 2 papers regarding acceleration of web interface generation through multilingual voice commands. <br/>
                                Created a web app using Flask and Azure Cognitive Services where users can generate a web page using voice commands. <br />
                                <a
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=OSEqORMAAAAJ&citation_for_view=OSEqORMAAAAJ:u5HHmVD_uO8C"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                    style={{marginRight:25}}
                                >Paper 1</a> 
                                <a
                                    href="https://scholar.google.com/citations?view_op=view_citation&hl=en&user=OSEqORMAAAAJ&citation_for_view=OSEqORMAAAAJ:u-x6o8ySG0sC"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="project-name"
                                >Paper 2</a> 
                                
                                </p></div>

                        </div>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={4}>
                    </Grid> */}


                </Grid>
            </div>
        </Container>
    );
}
export default Others;
