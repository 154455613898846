import React from 'react';
import Container from '@material-ui/core/Container';
import comp from '../images/computer.png';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';

function First() {
	return (
		<Container className="first">
			<div className="center">
				<img src={comp} alt="computer" className="logo" />
				<h1>HIMANSHU RATTAN</h1>
				<span>software developer</span>
				<div class="social-container">
					<ul class="social-icons">
						<li>
							<a href="https://github.com/HimanshuRattan" rel="noopener noreferrer" target="_blank">
								<i class="fa fa-github" />
							</a>
						</li>

						<li>
							<a href="https://twitter.com/this_himanshu" rel="noopener noreferrer" target="_blank">
								<i class="fa fa-twitter" />
							</a>
						</li>
						<li>
							<a
								href="https://www.linkedin.com/in/himanshu-rattan/"
								rel="noopener noreferrer"
								target="_blank"
							>
								<i class="fa fa-linkedin" />
							</a>
						</li>

						<li>
							<a href="https://medium.com/@himanshurattan" rel="noopener noreferrer" target="_blank">
								<i class="fa fa-medium" />
							</a>
						</li>
						<li>
							<a
								href="https://www.instagram.com/hmmanshurattan/"
								rel="noopener noreferrer"
								target="_blank"
							>
								<i class="fa fa-instagram" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</Container>
	);
}
export default First;
