import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import gif from '../images/giphy1.webm';

function Now() {
	return (
		<React.Fragment>
			<CssBaseline />
			<Container className="now">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={6}>
						<h1>What I'm doing now -</h1>
						<span className="date">August 9, 2022</span>
						<p>
							<i class="fa fa-terminal" aria-hidden="true" />📍 &nbsp;&nbsp;Location<br />
							<p className="answer">Chandigarh!</p>
							<br />
							<i class="fa fa-terminal" aria-hidden="true" />👨‍💻 &nbsp;&nbsp;Working<br />
							<p className="answer">
								SWE at
								<a href="https://nielseniq.com/global/en/" rel="noopener noreferrer" target="_blank">
									{' '}
									NielsenIQ{' '}
								</a>
								{/* <br />3. Intern at{' '}
								<a href="https://www.kaloory.com/#/" rel="noopener noreferrer" target="_blank">
									Kaloory
								</a>
								<br />
								4. Volunteering at{' '}
								<a href="https://oorjaa.org/" rel="noopener noreferrer" target="_blank">
									Oorjaa */}
								{/* </a> */}
							</p>
							<br />
							<i class="fa fa-terminal" aria-hidden="true" />🧠 &nbsp;&nbsp;Learning<br />
							<p className="answer">
								Practicing Chess ♟️. Hit me up for a challenge.
							</p>
							<br />
							<i class="fa fa-terminal" aria-hidden="true" />🎶 &nbsp;&nbsp;Listening<br />
							<p className="answer">Techno. A lot of techno.</p>
							<br />
							<i class="fa fa-terminal" aria-hidden="true" />📚 &nbsp;&nbsp;Reading<br />
							<p className="answer">A lot of interesting newsletters.</p>
							<br />
							<i class="fa fa-terminal" aria-hidden="true" />🤔 &nbsp;&nbsp;Thinking of<br />
							<p className="answer">Starting a podcast. You want to too? Get in touch!</p>
							<br />
						</p>
						<a href="https://nownownow.com/about" rel="noopener noreferrer" target="_blank">
							What's this?
						</a>
					</Grid>
					<Grid item xs={12} sm={12} md={6} className="now-col">
						<figure>
							<video alt="a funny gif" className="now-gif" controls loop autoplay muted>
								<source src={gif} type="video/mp4" />
								<source src={gif} type="video/webm" />
							</video>

							{/* <img src={gif} alt="a funny gif" className="now-gif" /> */}
							<figcaption>
								<a href="https://giphy.com/cartoonhangover" rel="noopener noreferrer" target="_blank">
									GIF by Cartoon Hangover
								</a>
							</figcaption>
						</figure>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment >
	);
}
export default Now;
