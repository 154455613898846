import React from 'react';
import Container from '@material-ui/core/Container';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import logo from '../images/himanshu.webp';
import logo from '../images/himanshu1.jpg';

function Aboutme() {
	return (
		<Container className="first about-me">
			{/* <Grid container spacing={3}>
			<Grid item style={{alignItems: 'center'}}>
							<h1>About Me</h1>
						</Grid>
			</Grid> */}
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={6} className="photo-grid">
					<Grid container spacing={3}>
						{/* <Grid item>
							<h1>ABOUT ME</h1>
						</Grid> */}
						<Grid item>
							<img src={logo} className="my-photo" alt="himanshu rattan is here" />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
				<h1 style={{marginLeft: 16}}>About Me</h1>
					<p style={{marginLeft: 16}}>
						Hi! My name is Himanshu Rattan.<br />
						I am a software engineer at NielsenIQ and a Computer Science graduate from SRM Institute of Science and Technology,
						Kattankulathur. I am a web/software developer who likes to build stuff for fun. Currently I write Python during the day and JavaScript at night.<br />
						My work is focused on creating clean, creative and responsive interfaces, generally for the Web.
						Currently open to freelancing opportunities for the same.<br />
					</p>
					<Grid container spacing={3} className="technologies">
						<p>
							Technologies I have been working with recently: <br />
						</p>
						<Grid item xs={6} sm={6} md={6}>
							<i class="fa fa-terminal" aria-hidden="true" /> Python<br />
							<i class="fa fa-terminal" aria-hidden="true" /> SQL<br />
							<i class="fa fa-terminal" aria-hidden="true" /> JavaScript<br />
						</Grid>
						<Grid item xs={6} sm={6} md={6}>
							<i class="fa fa-terminal" aria-hidden="true" /> Python<br />
							<i class="fa fa-terminal" aria-hidden="true" /> Shell Scripting<br />
							<i class="fa fa-terminal" aria-hidden="true" />Microsoft Azure<br />

						</Grid>
					</Grid>
					<p className="resume">
						<a
							href="https://drive.google.com/file/d/1yrRRNJ0l26AaOwZSW2tW76MjBe4Tc7qG/view?usp=sharing"
							rel="noopener noreferrer"
							target="_blank"
						>
							Download Resume <i class="fa fa-bookmark-o" aria-hidden="true" />
						</a>
					</p>
				</Grid>
			</Grid>
		</Container>
	);
}
export default Aboutme;
